<template>
  <div class="relation user-wrapper">
    <div class="user-header">关联管理</div>
    <div class="user-container justify-center" v-loading="isLoading">
      <div v-if="isBinding === STATUS_BIND" class="flex flex-col items-center">
        <svg class="icon" aria-hidden="true" style="font-size: 72px">
          <use xlink:href="#icon-icon_chenggong"></use>
        </svg>
        <p class="msg">关联状态：已关联国家公共服务体系管理平台</p>
        <Button type="primary" plain :style="{ width: '120px', marginTop: '80px' }" @click="closeRelation"
          >解除关联</Button
        >
      </div>
      <div v-if="isBinding === STATUS_UNBIND" class="flex flex-col items-center">
        <svg class="icon" aria-hidden="true" style="font-size: 72px">
          <use xlink:href="#icon-icon_jinggao"></use>
        </svg>
        <p class="msg">关联状态： 未关联国家公共服务体系管理平台</p>
        <p class="tip">请从国家公共服务体系管理平台跳转至青鹿教学云平台进行关联</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @desc 关联管理
 */
import { getOAuth2bindingInfo, unbindOAuth2 } from '@/api/user';
import { Button } from 'element-ui';

const STATUS_BIND = 1; // 已绑定
const STATUS_UNBIND = 0; // 未绑定

// 0：成功，1：失败，2：会话过期，3：表示没有权限

export default {
  components: {
    Button,
  },
  data() {
    return {
      STATUS_BIND,
      STATUS_UNBIND,

      isLoading: true,
      isBinding: undefined,
    };
  },
  methods: {
    closeRelation() {
      this.$msgbox
        .confirm('确定解除关联吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          unbindOAuth2().then(() => {
            this.$message({
              type: 'success',
              message: '已解除关联!',
            }).finally(() => {
              this.getData();
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getData() {
      this.isLoading = true;
      getOAuth2bindingInfo({})
        .then(({ result }) => {
          // console.log(result);
          this.isBinding = result.isBinding;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import '../../user.scss';

.relation {
  .msg {
    display: flex;
    justify-content: center;

    font-size: 18px;
    font-weight: 400;
    color: #2c2f2e;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .tip {
    font-size: 14px;
    font-weight: 400;

    color: #8b8f8f;
    line-height: 24px;
  }
}
</style>
